import {
  START_SOFTSKILLS_USER_COURSE,
  START_SOFTSKILLS_USER_COURSE_SUCCESS,
  START_SOFTSKILLS_USER_COURSE_CLEAN,
  START_SOFTSKILLS_USER_COURSE_ERROR,
} from "./actionTypes"

export const startSoftskillsUserCourse = form => ({
  type: START_SOFTSKILLS_USER_COURSE,
  payload: { form },
})

export const startSoftskillsUserCourseSuccess = form => {
  return {
    type: START_SOFTSKILLS_USER_COURSE_SUCCESS,
    payload: { form },
  }
}

export const startSoftskillsUserCourseClean = () => {
  return {
    type: START_SOFTSKILLS_USER_COURSE_CLEAN,
    payload: {},
  }
}

export const startSoftskillsUserCourseError = error => {
  return {
    type: START_SOFTSKILLS_USER_COURSE_ERROR,
    payload: error,
  }
}
