import { useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Row,
  Col,
  CardImg,
  Progress,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Button,
} from "reactstrap"
import fester from "../../../assets/images/logo_soft_skills.svg"
import moment from "common/moment_i18n"

import {
  addFav,
  addFavClean,
  removeFav,
  removeFavClean,
  getCoursesByUser,
} from "store/actions"

const CoursesWidget = props => {
  const [dropDowns, setDropDowns] = useState(false)
  const [modal, setModal] = useState(false)

  const { addFavDone, removeFavDone, user } = useSelector(store => ({
    addFavDone: store.FavAdd.done,
    removeFavDone: store.FavRemove.done,
    user: store.Profile.user,
  }))

  const userId = user?.idUsuario ?? 0

  const dispatch = useDispatch()

  const handleAddFav = courseId => {
    //dispatch(addFav(courseId))
  }

  const handleRemoveFav = courseId => {
    // dispatch(removeFav(idCourse))
  }

  if (addFavDone) {
    // dispatch(getCoursesByUser(idUser))
    // dispatch(addFavClean())
  }

  if (removeFavDone) {
    // dispatch(getCoursesByUser(idUser))
    // dispatch(removeFavClean())
  }

  const getModality = modalityId => {
    if (modalityId == 1) return "Online Course"
    if (modalityId == 2) return "Material"
    if (modalityId == 3) return "Playlist"
    if (modalityId == 4) return "Presencial"
    if (modalityId == 5) return "External content"
  }

  const secondsToHms = d => {
    d = Number(d)
    d = d * 60
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? " hora, " : " horas, ") : ""
    var mDisplay = m > 0 ? m + (m == 1 ? " minuto, " : " minutos, ") : ""
    var sDisplay = s + (s == 1 ? " second" : " segundos")
    return hDisplay + mDisplay + sDisplay
  }

  return (
    <Card>
      <CardBody>
        <Row className="no-gutters align-items-center">
          <Col md={3} className="align-self-start">
            <Link to={`/soft-skills/courses/info/${props.id}`}>
              <CardImg
                className="img-fluid"
                src={props.image == "" ? fester : props.image}
                alt="Imagen"
              />
            </Link>
          </Col>
          <Col md={9} className="align-self-start">
            <div className="d-flex justify-content-between">
              <h3>
                <Link to={`/soft-skills/courses/info/${props.id}`}>
                  {props.title}
                </Link>
              </h3>
              {props.finished && (
                <div>
                  <span
                    className="badge bg-pill bg-success"
                    style={{ fontSize: "16px", borderRadius: "14px" }}
                  >
                    {" "}
                    <i className="fa fa-flag-checkered"></i> Aprobado
                  </span>
                </div>
              )}
            </div>
            <h4 className="alt-celf-color">{props.ability}</h4>
            <hr />
            {props.started && props.dateStart && (
              <p>
                * {props.finished ? "Inició" : "Inicio"}:{" "}
                {moment(props.dateStart)
                  .locale("es")
                  .format("D [de] MMM [de] YYYY")}
              </p>
            )}
            {props.finished && props.dateFinish && (
              <p>
                * Finalizó:{" "}
                {moment(props.dateFinish)
                  .locale("es")
                  .format("D [de] MMM [de] YYYY")}
              </p>
            )}
            {/* !props.started && (
              <p>
                El objetivo del curso es brindar al alumno la información
                necesaria para poder realizar la reparación de estructuras con
                ayuda de productos enfocados al concreto.
              </p>
            ) */}
            <Row>
              <Col lg={7} md={6} sm={8} className="pt-2">
                Plataforma: <strong>{getModality(props.modality)}</strong>
              </Col>

              <Col lg={5} md={6} sm={4}>
                <Link
                  to={`/soft-skills/courses/info/${props.id}`}
                  className="btn btn-primary float-end"
                >
                  Ir al curso <i className="fa fa-arrow-right"></i>
                </Link>
                <div className="float-end" style={{ width: "20px" }}>
                  &nbsp;
                </div>
                <Dropdown
                  className="float-end"
                  isOpen={dropDowns}
                  toggle={() => {
                    setDropDowns(!dropDowns)
                  }}
                >
                  <DropdownToggle color="light" className="btn btn-light">
                    <i className="fa fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="white-celf-background">
                    <DropdownItem
                      onClick={() => {
                        props.fav
                          ? handleRemoveFav(props.id)
                          : handleAddFav(props.id)
                      }}
                    >
                      <i className={`fa${props.fav ? "r" : ""} fa-star`} />{" "}
                      {props.fav
                        ? "Remover de favoritos"
                        : "Añadir a favoritos"}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

CoursesWidget.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  ability: PropTypes.string,
  modality: PropTypes.string,
  image: PropTypes.string,
  percentage: PropTypes.number,
  minutes: PropTypes.number,
  fav: PropTypes.bool,
  started: PropTypes.bool,
  finished: PropTypes.bool,
  dateStart: PropTypes.string,
  dateFinish: PropTypes.string,
}

export default CoursesWidget
