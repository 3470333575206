import CoursesTab from "components/SoftSkills/CourseUsers/CoursesTab"
import { Fragment, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { getSoftskillsUserCourses } from "store/actions"

const WelcomeSoftSkills = () => {
  const [activeTab, setActiveTab] = useState("1")
  const dispatch = useDispatch()

  const { user, courses, loading } = useSelector(store => ({
    user: store.Profile.user,
    courses: store.SoftskillsUserCoursesList.courses,
    loading: store.SoftskillsUserCoursesList.loading,
  }))

  const userId = user?.idUsuario ?? 0

  useEffect(() => {
    dispatch(getSoftskillsUserCourses(userId))
  }, [userId])

  return (
    <Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Soft Skills | La Academia Fester</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col xs={12}>
                          <h3>Bienvenido a Soft Skills</h3>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <CoursesTab courses={courses} loading={loading} userId={userId} />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  )
}

export default WelcomeSoftSkills
