import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, Button, Label } from "reactstrap"
import Switch from "react-switch"
import Select from "react-select"
import {
  addSoftskillAbility,
  addSoftskillCourse,
  editSoftskillAbility,
  editSoftskillCourse,
  getSoftskillsAbilities,
} from "store/actions"
import { set } from "lodash"
import UploadFileModal from "components/Common/UploadFileModal"

const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 10,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Sí
    </div>
  )
}

const SoftskillsCoursesForm = props => {
  const course = props.course
  const [formError, setFormError] = useState(false)
  const [status, setStatus] = useState(course.status)
  const [abilitySelected, setAbilitySelected] = useState(0)
  const [modalitySelected, setModalitySelected] = useState(0)
  const [valorationSelected, setValorationSelected] = useState(0)
  const [languageSelected, setLanguageSelected] = useState(0)
  const [image, setImage] = useState("")
  const [uploadImage, setUploadImage] = useState(false)

  const insert = props.insert ?? true

  const dispatch = useDispatch()

  const {
    loadingAdd,
    doneAdd,
    errorAdd,
    loadingUpdate,
    errorUpdate,
    doneUpdate,
    whoIAm,
    courses,
    abilities,
  } = useSelector(state => ({
    loadingAdd: state.SoftskillsCoursesAdd.loading,
    errorAdd: state.SoftskillsCoursesAdd.error,
    doneAdd: state.SoftskillsCoursesAdd.done,
    loadingUpdate: state.SoftskillsCoursesEdit.loading,
    errorUpdate: state.SoftskillsCoursesEdit.error,
    doneUpdate: state.SoftskillsCoursesEdit.done,
    whoIAm: state.Profile.user,
    courses: state.SoftskillsCoursesList.courses,
    abilities: state.SoftskillsAbilitiesList.abilities,
  }))

  const userId = whoIAm?.idUsuario ?? 0

  const modalityOptions = [
    { label: "Online Course", value: 1 },
    { label: "Material", value: 2 },
    { label: "Playlist", value: 3 },
    { label: "Presencial", value: 4 },
    { label: "External content", value: 5 },
  ]

  const languageOptions = [
    { label: "Español", value: 1 },
    { label: "Inglés", value: 2 },
    { label: "Portugués", value: 3 },
    { label: "Aleman", value: 4 },
  ]

  const valorationOptions = [
    { label: "Sí, agregar", value: 1 },
    { label: "No agregar", value: 0 },
  ]

  useEffect(() => {
    if (course !== undefined) {
      if (course.image !== undefined) {
        setImage(course.image)
      }

      if (abilities.length === 0) {
        dispatch(getSoftskillsAbilities())
      }

      setModalitySelected(course.abilityId)

      setLanguageSelected(course.languageId)

      abilities.forEach((value, index) => {
        const selectedAbility = course.abilityId === value.abilityId

        if (selectedAbility) {
          setAbilitySelected({
            label: `${value.abilityName}`,
            value: value.abilityId,
          })
        }
      })

      const modality = modalityOptions.find((modality, index) => {
        modality.value === course.modalityId
      })

      setModalitySelected(modality ?? modalityOptions[0])

      const valoration = valorationOptions.find((element, index) => {
        element.value === course.evaluation
      })

      setValorationSelected(valoration ?? valorationOptions[0])

      const language = languageOptions.find((element, index) => {
        element.value === course.languageId
      })

      setLanguageSelected(language ?? languageOptions[0])
    }
  }, [course, abilities])

  const options =
    abilities !== undefined
      ? abilities
          .filter((value, index) => value.status)
          .map(value => {
            return {
              label: `${value.abilityName}`,
              value: value.abilityId,
            }
          })
      : []

  const optionGroup = [
    {
      label: "Habilidad",
      options: options,
    },
  ]

  const modalityOptionGroup = [
    {
      label: "Modalidad",
      options: modalityOptions,
    },
  ]

  const languageOptionGroup = [
    {
      label: "Idioma",
      options: languageOptions,
    },
  ]

  const valorationOptionGroup = [
    {
      label: "Agregar valoración del curso",
      options: valorationOptions,
    },
  ]

  const handleAbilitySelected = event => {
    setAbilitySelected(event)
  }

  const handleModalitySelected = event => {
    setModalitySelected(event)
  }

  const handleLanguageSelected = event => {
    setLanguageSelected(event)
  }

  const handleValorationSelected = event => {
    setValorationSelected(event)
  }

  const handleSubmit = (event, errors, values) => {
    if (errors.length > 0) {
      setFormError(true)
      return
    }

    const request = {
      name: values.name,
      status: status ? 1 : 0,
      userId: userId,
      abilityId: abilitySelected.value,
      modalityId: modalitySelected.value,
      languageId: languageSelected.value,
      description: values.description,
      link: values.link,
      imageUpload: values.imageUpload ? 1 : 0,
      openText: values.openText ? 1 : 0,
      questions: values.questions ? 1 : 0,
      limitDays: parseInt(values.limitDays),
      duration: parseInt(values.duration),
      evaluation: valorationSelected.value,
      tags: values.tags,
      image: image,
    }

    if (insert) {
      dispatch(addSoftskillCourse(request))
    } else {
      const request = {
        ...request,
        courseId: course.courseId,
      }
      dispatch(editSoftskillCourse(request))
    }
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <h2>Información del curso</h2>
          <p>Ingresa la información solicitada</p>
        </Col>
      </Row>
      <hr />
      <AvForm className="form-horizontal" onSubmit={handleSubmit}>
        {errorAdd !== "" && (
          <div className="alert alert-danger">
            Ocurrió un error al registrar el curso: {errorAdd}
          </div>
        )}
        {errorUpdate !== "" && (
          <div className="alert alert-danger">
            Ocurrió un error al editar el curso: {errorUpdate}
          </div>
        )}
        {formError && (
          <div className="alert alert-danger">
            Por favor corrija los errores en el formulario antes de continuar.
          </div>
        )}
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Nombre del curso:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="name"
              value={course.courseName}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Habilidad:
            </Label>
          </Col>
          <Col md={9}>
            <Select
              value={abilitySelected}
              isMulti={false}
              onChange={event => {
                handleAbilitySelected(event)
              }}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Modalidad del curso:
            </Label>
          </Col>
          <Col md={9}>
            <Select
              value={modalitySelected}
              isMulti={false}
              onChange={event => {
                handleModalitySelected(event)
              }}
              options={modalityOptionGroup}
              classNamePrefix="select2-selection"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="language">
              * Idioma:
            </Label>
          </Col>
          <Col md={9}>
            <Select
              value={languageSelected}
              isMulti={false}
              onChange={event => {
                handleLanguageSelected(event)
              }}
              options={languageOptionGroup}
              classNamePrefix="select2-selection"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              Descripción:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="description"
              value={course.description}
              className="form-control"
              placeholder=""
              type="textarea"
              validate={{}}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Link del curso o material:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="link"
              value={course.link}
              className="form-control"
              placeholder=""
              type="text"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Tipo de prueba para validar completado el curso</Label>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={{ size: 12 }}>
            <AvGroup check className="mt-2 mb-2">
              <AvInput
                name="imageUpload"
                type="checkbox"
                value={course.imageUpload}
              />
              <Label for="imageUpload">
                Cargar imagen de pantalla del curso completado
              </Label>
              <AvFeedback>
                Usuario sube la imagen como prueba de curso completado.
              </AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={{ size: 12 }}>
            <AvGroup check className="mt-2 mb-2">
              <AvInput
                name="openText"
                type="checkbox"
                value={course.openText}
              />
              <Label for="openText">
                Habilitar texto abierto para ingresar comentarios
              </Label>
              <AvFeedback>
                Usuario tiene disponible un campo de texto para escribir
                comentarios
              </AvFeedback>
            </AvGroup>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={{ size: 12 }}>
            <AvGroup check className="mt-2 mb-2 ml-2">
              <AvInput
                name="questions"
                type="checkbox"
                value={course.questions}
              />
              <Label for="questions">
                Generar preguntas para validar curso completado
                <br />
                <small>
                  Usuario tendrá que contestar preguntas de falso/verdadero para
                  validar conocimiento adquirido
                </small>
              </Label>
            </AvGroup>
          </Col>
        </Row>
        <Row className="mb-3 mt-2">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Agregar valoración del curso:
            </Label>
          </Col>
          <Col md={9}>
            <Select
              value={valorationSelected}
              isMulti={false}
              onChange={event => {
                handleValorationSelected(event)
              }}
              options={valorationOptionGroup}
              classNamePrefix="select2-selection"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="limitDays">
              * Días límite para realización del curso:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="limitDays"
              value={course.limitDays}
              className="form-control"
              placeholder=""
              type="number"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="duration">
              * Duración en minutos:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="duration"
              value={course.duration}
              className="form-control"
              placeholder=""
              type="number"
              validate={{
                required: {
                  value: true,
                  errorMessage: "Este campo es requerido",
                },
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              Etiquetas:
            </Label>
          </Col>
          <Col md={9}>
            <AvField
              name="tags"
              value={course.tags}
              className="form-control"
              placeholder=""
              type="text"
              validate={{}}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <label>* Imagen de portada:</label>
          </Col>
          <Col md={9} className="d-flex justify-content-start">
            <button
              className="btn btn-dark"
              type="button"
              onClick={() => setUploadImage(true)}
            >
              <i className="fa fa-upload"></i> Subir imagen
            </button>
          </Col>
          {image !== "" && (
            <Col md={{ size: 9, offset: 3 }}>
              <Row className="mt-3 mb-3">
                <Col md={4}>
                  <div className="p2">
                    <img src={image} alt="image" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Label className="col-form-label" for="name">
              * Publicar curso:
            </Label>
          </Col>
          <Col md={9}>
            <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor={"#ed1c2e"}
              onChange={() => {
                setStatus(!status)
              }}
              checked={status}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xl={8} lg={8} md={8} sm={8} xs={0}></Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <Button className={`float-right  btn-block`} type="submit">
              {!loadingAdd && !loadingUpdate && <span>Guardar</span>}
              {(loadingAdd || loadingUpdate) && <span>Guardando...</span>}
            </Button>
          </Col>
          <Col xl={2} lg={2} md={2} sm={2} xs={6}>
            <button
              type="button"
              onClick={() => props.onCancel()}
              className="btn btn-light btn-block"
            >
              Cancelar
            </button>
          </Col>
        </Row>
        {doneAdd && insert && errorAdd === "" && (
          <SweetAlert
            title="Curso registrado exitosamente"
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              props.onDone()
            }}
          >
            <p>El curso fue registrado exitosamente.</p>
          </SweetAlert>
        )}
        {doneUpdate && !insert && errorUpdate === "" && (
          <SweetAlert
            title="Curso actualizado exitosamente"
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              props.onDone()
            }}
          >
            <p>El curso fue actualizado exitosamente.</p>
          </SweetAlert>
        )}
        <style jsx="true">{`
          .radio-sublabel {
            color: gray;
            padding-left: 2rem;
          }
          .users-group {
            padding-left: 3rem;
          }
          span.circle {
            display: block;
            width: 50px;
            height: 50px;
            line-height: 20px;
            background-color: #d7aed3;
            text-align: center;
            border-radius: 50%;
            font-size: 20px;
            padding-top: 25%;
          }
        `}</style>
      </AvForm>
      {uploadImage && (
        <UploadFileModal
          show={true}
          onCancel={() => setUploadImage(false)}
          onConfirm={file => {
            setUploadImage(false)
            setImage(file)
          }}
          image={true}
        />
      )}
    </Fragment>
  )
}

SoftskillsCoursesForm.propTypes = {
  insert: PropTypes.bool,
  course: PropTypes.object,
  history: PropTypes.object,
  onDone: PropTypes.func,
  onCancel: PropTypes.func,
}

export default withRouter(SoftskillsCoursesForm)
