import { use } from "echarts"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "reactstrap"
import {
  addSoftskillAbilityUser,
  addSoftskillAbilityUserClean,
  getUser,
} from "store/actions"

const AbilitiesForUserModal = props => {
  const dispatch = useDispatch()
  const [finalAbilities, setFinalAbilities] = useState([])

  const { error, loading, done } = useSelector(state => ({
    error: state.SoftskillAbilityUserAdd.error,
    loading: state.SoftskillAbilityUserAdd.loading,
    done: state.SoftskillAbilityUserAdd.done,
  }))

  useEffect(() => {
    let newAbilities = []
    for (let i = 0; i < props.abilities.length; i++) {
      let found = false
      for (let j = 0; j < props.user.abilities.length; j++) {
        if (
          props.abilities[i].abilityId === props.user.abilities[j].abilityId
        ) {
          found = true
        }
      }
      if (!found) newAbilities.push(props.abilities[i])
    }

    setFinalAbilities(newAbilities)
  }, [props.abilities, props.user])

  if (done) {
    dispatch(addSoftskillAbilityUserClean())
    dispatch(getUser(props.user.userId))
  }

  return (
    <Modal isOpen={true} backdrop={"static"} size="md" id="staticBackdrop">
      <div className="modal-header">
        <h5 className="modal-title">Asignar habilidad</h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            props.onClose()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th>Habilidad</th>
              <th>Añadir</th>
            </tr>
          </thead>
          <tbody>
            {finalAbilities.map(ability => (
              <tr key={ability.abilityId}>
                <td>{ability.abilityName}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-rounded btn-primary"
                    onClick={() => {
                      dispatch(
                        addSoftskillAbilityUser({
                          userId: props.user.userId,
                          abilityId: ability.abilityId,
                          registerUserId: props.userId,
                        })
                      )
                    }}
                  >
                    <i className="fa fa-plus-circle"></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

AbilitiesForUserModal.propTypes = {
  onClose: PropTypes.func,
  abilities: PropTypes.array,
  user: PropTypes.object,
  userId: PropTypes.number,
}

export default AbilitiesForUserModal
