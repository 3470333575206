import React, { Component, Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb2"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import { getSoftskillsCourse } from "store/actions"
import UploadCourseImageModal from "components/SoftSkills/CourseUsers/UploadCourseImageModal"

const SoftskillsUserCourseDetail = props => {
  const { courseId } = useParams()

  const [showUploadImage, setShowUploadImage] = useState(false)

  const dispatch = useDispatch()

  const { user, course, loading } = useSelector(store => ({
    user: store.Profile.user,
    course: store.SoftskillCourseGet.course,
    loading: store.SoftskillCourseGet.loading,
  }))

  const userId = user?.idUsuario ?? 0

  const courseUser =
    course === undefined
      ? undefined
      : course.courseUsers.find(courseUser => courseUser.userId === userId)

  const started = courseUser !== undefined
  const courseStart =
    courseUser !== undefined ? courseUser.createdAt : undefined
  const finished = courseUser !== undefined ? courseUser.statusId === 2 : false
  const courseEnd = courseUser !== undefined ? courseUser.finishedAt : undefined

  useEffect(() => {
    dispatch(getSoftskillsCourse(courseId))
  }, [courseId])

  const getModality = modalityId => {
    if (modalityId == 1) return "Online Course"
    if (modalityId == 2) return "Material"
    if (modalityId == 3) return "Playlist"
    if (modalityId == 4) return "Presencial"
    if (modalityId == 5) return "External content"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Detalle de curso {course?.courseName ?? ""} | Soft Skills
          </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <Breadcrumbs
                breadcrumbItems={[
                  { key: "dashboard", title: "Inicio", link: "/soft-skills" },
                  { key: "my-course", title: course?.courseName ?? "Curso" },
                ]}
              />
            </Col>
          </Row>
          {course !== undefined && (
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={3}>
                        <img src={course.image} className="img-fluid" />
                      </Col>
                      <Col md={9}>
                        <Row>
                          <Col md={9}>
                            <h2 className="text-secondary">
                              {course.courseName ?? ""}
                            </h2>
                            <h4 className="text-primary">
                              {course.ability?.abilityName ?? ""}
                            </h4>
                            <p>Plataforma: {getModality(course.modalityId)}</p>
                          </Col>
                          <Col md={3}>
                            {finished ? (
                              <h3>
                                <span
                                  className="badge bg-pill bg-success pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  Completado
                                </span>
                              </h3>
                            ) : (
                              <h3>
                                <span
                                  className="badge bg-pill bg-secondary pt-2 pl-2 pr-2"
                                  style={{ display: "block" }}
                                >
                                  Pendiente de completar
                                </span>
                              </h3>
                            )}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={9}>
                            <h3>Objetivo del curso</h3>
                            <p>{course.description}</p>
                            <p className="mt-2">
                              <strong>Link para ingresar:</strong>{" "}
                              <a
                                href={course.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {course.link}
                              </a>
                            </p>
                          </Col>
                          <Col md={3}>
                            {finished ? (
                              <></>
                            ) : (
                              <button
                                onClick={() => setShowUploadImage(true)}
                                type="button"
                                className="btn btn-primary btn-block btn-xl"
                              >
                                <i className="fa fa-check"></i> Completar curso
                              </button>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      {showUploadImage && (
        <UploadCourseImageModal
          show={true}
          onCancel={() => setShowUploadImage(false)}
          onConfirm={() => setShowUploadImage(false)}
        />
      )}
    </React.Fragment>
  )
}

export default SoftskillsUserCourseDetail
