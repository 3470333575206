import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import SessionExtend from "./auth/extend/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer"

// Uers
import UsersList from "./users/list/reducer"
import UserAdd from "./users/add/reducer"
import UserEdit from "./users/edit/reducer"

// Profiles
import ProfilesList from "./profiles/reducer"

// Customers
import CustomersList from "./customers/list/reducer"
import CustomerAdd from "./customers/add/reducer"
import CustomerEdit from "./customers/edit/reducer"
import CustomerGet from "./customers/get/reducer"

// Groups
import GroupsList from "./groups/list/reducer"
import GroupAdd from "./groups/add/reducer"
import GroupGet from "./groups/get/reducer"
import GroupEdit from "./groups/edit/reducer"
import GroupParticipantAdd from "./groups/add_participant/reducer"
import GroupParticipantsList from "./groups/get_participants/reducer"
import GroupParticipantRemove from "./groups/remove_participant/reducer"
import GroupCourseAdd from "./groups/add_course/reducer"
import GroupCoursesList from "./groups/get_courses/reducer"
import GroupCourseRemove from "./groups/remove_course/reducer"
import GroupCourseReset from "./groups/reset_course/reducer"
import GroupParticipantCourseReset from "./groups/reset_participant_course/reducer"
import GroupUserReset from "./groups/reset_user/reducer"

// Positions
import PositionsList from "./positions/list/reducer"
import PositionAdd from "./positions/add/reducer"
import PositionEdit from "./positions/edit/reducer"

// Businesses
import BusinessesList from "./businesses/list/reducer"
import BusinessAdd from "./businesses/add/reducer"
import BusinessEdit from "./businesses/edit/reducer"
import BusinessGet from "./businesses/get/reducer"

// Knowledge categories
import KnowledgeCategoriesList from "./knowledge-categories/list/reducer"
import KnowledgeCategoryAdd from "./knowledge-categories/add/reducer"
import KnowledgeCategoryEdit from "./knowledge-categories/edit/reducer"

// Levels
import LevelsList from "./levels/list/reducer"
import LevelAdd from "./levels/add/reducer"
import LevelEdit from "./levels/edit/reducer"

// Skills
import SkillsList from "./skills/list/reducer"
import SkillAdd from "./skills/add/reducer"
import SkillEdit from "./skills/edit/reducer"

// Course knowledges
import CourseKnowledgesList from "./course-knowledges/list/reducer"
import CourseKnowledgeAdd from "./course-knowledges/add/reducer"
import CourseKnowledgeEdit from "./course-knowledges/edit/reducer"

// Support material types
import SupportMaterialTypesList from "./support-material-types/list/reducer"
import SupportMaterialTypeAdd from "./support-material-types/add/reducer"
import SupportMaterialTypeEdit from "./support-material-types/edit/reducer"
import SupportMaterialTypeGet from "./support-material-types/get/reducer"

// Courses
import CoursesList from "./courses/list/reducer"
import CourseGet from "./courses/get/reducer"
import CourseAdd from "./courses/add/reducer"
import CourseEdit from "./courses/edit/reducer"
import CourseStart from "./courses/start/reducer"

// Lessons
import LessonsList from "./lessons/list/reducer"
import LessonGet from "./lessons/get/reducer"
import LessonAdd from "./lessons/add/reducer"
import LessonEdit from "./lessons/edit/reducer"
import LessonRemove from "./lessons/remove/reducer"
import LessonStart from "./lessons/start/reducer"
import LessonFinish from "./lessons/finish/reducer"
import LessonProgress from "./lessons/progress/reducer"

// Blocks
import BlocksList from "./blocks/list/reducer"
//import BlockGet from "./blocks/get/reducer"
import BlockAdd from "./blocks/add/reducer"
import BlockEdit from "./blocks/edit/reducer"
import BlockRemove from "./blocks/remove/reducer"

// Support materials
import SupportMaterialsList from "./support-materials/list/reducer"
import SupportMaterialAdd from "./support-materials/add/reducer"
import SupportMaterialRemove from "./support-materials/remove/reducer"

// Shopping areas
import ShoppingAreasList from "./shopping-areas/list/reducer"
import ShoppingAreasAdd from "./shopping-areas/add/reducer"
import ShoppingAreasEdit from "./shopping-areas/edit/reducer"

// Quizzes
import QuizzList from "./quizzes/list/reducer"
import QuizzAdd from "./quizzes/add/reducer"
import QuizzEdit from "./quizzes/edit/reducer"
import QuizzRemove from "./quizzes/remove/reducer"
import QuizzAnswersList from "./quizzes/list_answer/reducer"
import QuizzAnswerAdd from "./quizzes/add_answer/reducer"
import QuizzAnswerEdit from "./quizzes/edit_answer/reducer"
import QuizzAnswerRemove from "./quizzes/remove_answer/reducer"
import QuizzAnswerUserAdd from "./quizzes/add_answer_user/reducer"
import QuizzAnswerUserGet from "./quizzes/get_answer_user/reducer"

// Exams
import ExamAdd from "./exams/add/reducer"
import ExamGet from "./exams/get/reducer"
import ExamEdit from "./exams/edit/reducer"
import ExamTry from "./exams/try/reducer"

// Questions
import QuestionAdd from "./questions/add/reducer"
import QuestionEdit from "./questions/edit/reducer"
import QuestionGet from "./questions/get/reducer"
import QuestionsList from "./questions/list/reducer"
import QuestionRemove from "./questions/remove/reducer"

// Answers
import AnswersAdd from "./answers/add/reducer"
import AnswerEdit from "./answers/edit/reducer"
import AnswerGet from "./answers/get/reducer"
import AnswersList from "./answers/list/reducer"

// Polls
import PollsList from "./polls/list/reducer"
import PollAdd from "./polls/add/reducer"
import PollEdit from "./polls/edit/reducer"
import PollTry from "./polls/try/reducer"

// Poll answers
import PollAnswerAdd from "./poll_answers/add/reducer"
import PollAnswerEdit from "./poll_answers/edit/reducer"
import PollAnswerRemove from "./poll_answers/remove/reducer"
import PollAnswerList from "./poll_answers/list/reducer"

// Notes
import NotesList from "./notes/list/reducer"
import NoteAdd from "./notes/add/reducer"
import NoteRemove from "./notes/remove/reducer"

// Images
import ImageAdd from "./images/add/reducer"

// Favos
import FavAdd from "./favs/add/reducer"
import FavRemove from "./favs/remove/reducer"

// Reports
import Report1List from "./reports/list_report1/reducer"
import Report2List from "./reports/list_report2/reducer"
import Report3List from "./reports/list_report3/reducer"
import Report4List from "./reports/list_report4/reducer"
import Report5List from "./reports/list_report5/reducer"
import Report6List from "./reports/list_report6/reducer"
import ReportAList from "./reports/list_reporta/reducer"
import ReportBList from "./reports/list_reportb/reducer"
import Report9List from "./reports/list_report9/reducer"
import ReportSurveyList from "./reports/list_report_survey/reducer"

// New requests
import AbilitiesList from "./abilities/list/reducer"

// Interfester
import LocationsList from "./locations/list/reducer"
import AllEventsList from "./events/list_all/reducer"
import EventCreate from "./events/create/reducer"
import EventUpdate from "./events/update/reducer"
import EventDelete from "./events/delete/reducer"

// Soft skills abilities
import SoftskillsAbilitiesList from "./softskills_abilities/list/reducer"
import SoftskillsAbilitiesAdd from "./softskills_abilities/add/reducer"
import SoftskillsAbilitiesEdit from "./softskills_abilities/edit/reducer"
import SoftskillsAbilitiesGet from "./softskills_abilities/get/reducer"
import SoftskillAbilityUserAdd from "./softskills_abilities/add_user/reducer"
import SoftskillAbilityUserRemove from "./softskills_abilities/remove_user/reducer"
import SoftskillAbilityUserPositionEdit from "./softskills_abilities/edit_user_position/reducer"

// Soft skills courses
import SoftskillsCoursesList from "./softskills_courses/list/reducer"
import SoftskillsCoursesAdd from "./softskills_courses/add/reducer"
import SoftskillsCoursesEdit from "./softskills_courses/edit/reducer"
import SoftskillCourseGet from "./softskills_courses/get/reducer"
import SoftskillCourseTrash from "./softskills_courses/trash/reducer"
import SoftskillCoursePublish from "./softskills_courses/publish/reducer"

// Softskills user courses
import SoftskillsUserCoursesList from "./softskills_users/list/reducer"
import SoftskillsUserCoursesStart from "./softskills_users/start/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  SessionExtend,
  Dashboard,
  DashboardSaas,
  UsersList,
  UserAdd,
  UserEdit,
  ProfilesList,
  CustomersList,
  CustomerAdd,
  CustomerEdit,
  CustomerGet,
  GroupsList,
  GroupAdd,
  GroupGet,
  GroupEdit,
  GroupParticipantAdd,
  GroupParticipantsList,
  GroupParticipantRemove,
  GroupCourseAdd,
  GroupCoursesList,
  GroupCourseRemove,
  GroupCourseReset,
  GroupParticipantCourseReset,
  GroupUserReset,
  PositionsList,
  PositionAdd,
  PositionEdit,
  BusinessesList,
  BusinessAdd,
  BusinessEdit,
  BusinessGet,
  ShoppingAreasList,
  ShoppingAreasAdd,
  ShoppingAreasEdit,
  SupportMaterialTypesList,
  SupportMaterialTypeAdd,
  SupportMaterialTypeEdit,
  SupportMaterialTypeGet,
  CoursesList,
  CourseGet,
  CourseAdd,
  CourseEdit,
  CourseStart,
  LessonsList,
  LessonGet,
  LessonAdd,
  LessonEdit,
  LessonRemove,
  LessonStart,
  LessonFinish,
  LessonProgress,
  BlocksList,
  BlockAdd,
  BlockEdit,
  BlockRemove,
  SupportMaterialsList,
  SupportMaterialAdd,
  SupportMaterialRemove,
  KnowledgeCategoriesList,
  KnowledgeCategoryAdd,
  KnowledgeCategoryEdit,
  SkillsList,
  SkillAdd,
  SkillEdit,
  LevelsList,
  LevelAdd,
  LevelEdit,
  CourseKnowledgesList,
  CourseKnowledgeAdd,
  CourseKnowledgeEdit,
  ImageAdd,
  QuizzList,
  QuizzAdd,
  QuizzEdit,
  QuizzRemove,
  QuizzAnswersList,
  QuizzAnswerAdd,
  QuizzAnswerEdit,
  QuizzAnswerRemove,
  QuizzAnswerUserAdd,
  QuizzAnswerUserGet,
  ExamAdd,
  ExamGet,
  ExamEdit,
  ExamTry,
  QuestionAdd,
  QuestionEdit,
  QuestionGet,
  QuestionsList,
  QuestionRemove,
  AnswersAdd,
  AnswerEdit,
  AnswerGet,
  AnswersList,
  FavAdd,
  FavRemove,
  PollsList,
  PollAdd,
  PollEdit,
  PollTry,
  PollAnswerAdd,
  PollAnswerEdit,
  PollAnswerRemove,
  PollAnswerList,
  NotesList,
  NoteAdd,
  NoteRemove,
  Report1List,
  Report2List,
  Report3List,
  Report4List,
  Report5List,
  Report6List,
  ReportAList,
  ReportBList,
  Report9List,
  ReportSurveyList,
  AbilitiesList,
  LocationsList,
  AllEventsList,
  EventCreate,
  EventUpdate,
  EventDelete,
  SoftskillsAbilitiesList,
  SoftskillsAbilitiesAdd,
  SoftskillsAbilitiesEdit,
  SoftskillsAbilitiesGet,
  SoftskillAbilityUserAdd,
  SoftskillAbilityUserRemove,
  SoftskillsCoursesList,
  SoftskillsCoursesAdd,
  SoftskillsCoursesEdit,
  SoftskillCourseGet,
  SoftskillCourseTrash,
  SoftskillCoursePublish,
  SoftskillAbilityUserPositionEdit,
  SoftskillsUserCoursesList,
  SoftskillsUserCoursesStart,
})

export default rootReducer
