import React, { useState, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import moment from "moment"

import "../../../assets/scss/datatables.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  addSoftskillCourseClean,
  editSoftskillCourseClean,
  getSoftskillsAbilities,
  getSoftskillsCourses,
  publishSoftskillsCourse,
  publishSoftskillsCourseClean,
  trashSoftskillsCourse,
  trashSoftskillsCourseClean,
} from "store/actions"
import SoftskillsCoursesForm from "./SoftskillsCoursesForm"
import { Link } from "react-router-dom/cjs/react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"

const SoftskillsCoursesListTable = props => {
  const dispatch = useDispatch()
  const { SearchBar } = Search

  const [dropDown, setDropDown] = useState(0)
  const [publishCourse, setPublishCourse] = useState(undefined)
  const [trashCourse, setTrashCourse] = useState(undefined)

  const { error, loading, done, courses, publishDone, trashDone } = useSelector(
    state => ({
      error: state.SoftskillsCoursesList.error,
      loading: state.SoftskillsCoursesList.loading,
      courses: state.SoftskillsCoursesList.courses,
      done: state.SoftskillsCoursesList.done,
      publishDone: state.SoftskillCoursePublish.done,
      trashDone: state.SoftskillCourseTrash.done,
    })
  )

  useEffect(() => {
    dispatch(getSoftskillsCourses())
    dispatch(getSoftskillsAbilities())
  }, [])

  const getModality = modalityId => {
    if (modalityId === 1) return "Online Course"
    if (modalityId === 2) return "Material"
    if (modalityId === 3) return "Playlist"
    if (modalityId === 5) return "External Content"
    return "Presencial"
  }

  const handlePublishCourse = () => {
    dispatch(
      publishSoftskillsCourse({
        courseId: publishCourse.courseId,
        status: publishCourse.status ? 0 : 1,
      })
    )
  }

  const handleTrashCourse = () => {
    dispatch(
      trashSoftskillsCourse({
        courseId: trashCourse.courseId,
      })
    )
  }

  if (publishDone) {
    dispatch(publishSoftskillsCourseClean())
    dispatch(getSoftskillsCourses())
    setPublishCourse(undefined)
  }

  if (trashDone) {
    dispatch(trashSoftskillsCourseClean())
    dispatch(getSoftskillsCourses())
    setTrashCourse(undefined)
  }

  const buttonActions = (cell, row, rowIndex, extraData) => {
    const active = extraData?.active ?? 0
    return (
      <Dropdown
        key={`dropdown-${row.courseId}`}
        isOpen={row.courseId === active}
        toggle={() => {
          setDropDown(row.courseId === active ? 0 : row.courseId)
        }}
      >
        <DropdownToggle color="primary" className="btn btn-primary btn-md">
          Acciones <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="white-celf-background">
          <DropdownItem>
            <Link to={`/soft-skills/adm-courses/edit/${row.courseId}`}>
              Editar
            </Link>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setPublishCourse(row)
            }}
          >
            <Link to={`#`}>{row.status ? "No publicar" : "Publicar"}</Link>
          </DropdownItem>
          <DropdownItem onClick={() => setTrashCourse(row)}>
            <Link to={`#`}>Papelera</Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const columns = [
    {
      dataField: "courseId",
      text: "Id",
      sort: false,
      hidden: true,
    },
    {
      dataField: "ability.abilityName",
      text: "Habilidad",
      sort: true,
    },
    {
      dataField: "courseName",
      text: "Nombre del curso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span key={`courseName-${rowIndex}`}>
            <Link to={`/soft-skills/adm-courses/edit/${row.courseId}`}>
              {row.courseName}
            </Link>
          </span>
        )
      },
    },
    {
      dataField: "modalityId",
      text: "Modalidad",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span key={`modality-${rowIndex}`}>
            {getModality(row.modalityId)}
          </span>
        )
      },
    },
    {
      dataField: "status",
      text: "Estatus",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <h4 style={{ marginBottom: 0 }} key={`status-${row.courseId}`}>
            <span
              className={`pt-2 badge bg-${row.status ? "success" : "warning"}`}
            >
              {row.status ? "Publicado" : "No publicado"}
            </span>
          </h4>
        )
      },
    },
    {
      dataField: "lastActivity",
      text: "Última actualización",
      sort: true,
      align: "center",
      headerAlign: "center",
      // eslint-disable-next-line react/display-name
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <span key={row.courseId}>
            {moment(row.lastActivity)
              .locale("es")
              .format("DD/MM/YYYY HH:mm [hrs.]")}
          </span>
        )
      },
    },
    {
      dataField: "actions",
      text: "Acciones",
      sort: false,
      align: "center",
      headerAlign: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: buttonActions,
      formatExtraData: {
        active: dropDown,
      },
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: courses?.length,
    custom: true,
    showTotal: true,
  }

  const defaultSorted = [
    { dataField: "ability.abilityName", order: "asc" },
    { dataField: "courseName", order: "asc" },
  ]

  return loading || courses === undefined ? (
    <div className="mt-3 mb-3">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  ) : (
    <Fragment>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="courseId"
        columns={columns}
        data={courses}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="courseId"
            columns={columns}
            data={courses}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 pt-3">
                  <Col lg={2} md={3} sm={4} xs={6}>
                    <div className="d-grid">
                      <Link
                        className="btn btn-primary btn-rounded"
                        to={"/soft-skills/adm-courses/add"}
                      >
                        <i className="fa fa-plus"></i> Agregar curso
                      </Link>
                    </div>
                  </Col>
                  <Col
                    lg={{ size: 2, offset: 8 }}
                    md={{ size: 3, offset: 6 }}
                    sm={{ size: 4, offset: 4 }}
                    xs={6}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Buscar"
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <Fragment>
                      <BootstrapTable
                        keyField={"courseId"}
                        bordered={false}
                        striped={true}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                      {courses?.length <= 0 && (
                        <Row>
                          <Col md={12} className="text-center">
                            -- No hay registros --
                          </Col>
                        </Row>
                      )}
                    </Fragment>
                  </Col>
                </Row>

                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
      {publishCourse !== undefined && (
        <SweetAlert
          title={`${publishCourse.status ? "No publicar" : "Publicar"} curso`}
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handlePublishCourse}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setPublishCourse(undefined)}
        >
          <p>
            ¿Confirmas que este curso pasará a estatus{" "}
            {publishCourse.status ? "no publicado" : "publicado"}?
          </p>
        </SweetAlert>
      )}
      {trashCourse !== undefined && (
        <SweetAlert
          title={`Enviar curso a papelera`}
          warning
          confirmBtnBsStyle="secondary"
          confirmBtnText="Sí"
          onConfirm={handleTrashCourse}
          showCancel={true}
          cancelBtnBsStyle="light"
          cancelBtnText="No"
          onCancel={() => setTrashCourse(undefined)}
        >
          <p>¿Confirmas que enviarás este curso a papelera?</p>
        </SweetAlert>
      )}
    </Fragment>
  )
}

SoftskillsCoursesListTable.propTypes = {}

export default SoftskillsCoursesListTable
